import React, { useEffect, useState, useCallback } from 'react'
import { useUser } from 'lib/hooks'
import { useRouter } from 'next/router'
import Image from 'next/image'
import Modal from 'components/games/Modal'
import { firebaseClient } from 'lib/firebaseClient'
import GlobalLoading from './GlobalLoading'
import Steps from './Steps'
import Button from './Button'

export default function Auth({
  title,
  buttonText,
  type,
}: {
  title: string
  buttonText: string
  type: 'signin' | 'signup'
}) {
  const router = useRouter()
  const { redirectAfterTo } = router?.query

  const { user, isLoaded } = useUser()

  const [isEmailSentModalShown, setEmailSentModalShown] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (user) router.push('/games')
  }, [router, user])

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault()

      setLoading(true)

      try {
        await firebaseClient.auth().sendSignInLinkToEmail(email, {
          url: `${
            process.env.NODE_ENV === 'production'
              ? 'https://codingfantasy.com'
              : 'http://localhost:3000'
          }/enter-me?authMode=${type}${
            redirectAfterTo
              ? `&redirectAfterTo=${encodeURIComponent(decodeURIComponent(redirectAfterTo))}`
              : ''
          }`,
          handleCodeInApp: true,
        })

        window.localStorage.setItem('emailForSignIn', email)

        setEmailSentModalShown(true)
      } catch (error) {
        setErrorMsg(error.message)
        throw error
      } finally {
        setLoading(false)
      }
    },
    [email, redirectAfterTo, type]
  )

  return (
    <>
      <GlobalLoading loading={loading || !isLoaded} />
      <Modal isOpen={isEmailSentModalShown} canCloseModal={false} viewOnSmallScreenAsOnLarge>
        <div className="bg-white px-4 pt-8 pb-6 text-center text-gray-900">
          <div className="mb-8">
            <span className="text-6xl" role="img">
              📩
            </span>
          </div>
          <p className="text-2xl font-medium mb-2">Check your email!</p>
          <p className="text-lg ">
            We sent you a magic link to <strong>{email}</strong>. Click the link to{' '}
            {type === 'signin' ? 'login' : 'sign up'}.
          </p>
        </div>
      </Modal>
      <div className="flex items-center flex-col flex-auto h-full bg-black w-screen px-4">
        {/* {type === 'signup' && (
          <div className="flex justify-center mx-auto w-full mb-16">
            <Steps step={1} />
          </div>
        )} */}
        <div className="relative h-36 w-full">
          <Image
            src="/gamesAssets/gridattack/m-valcorian.png"
            layout="fill"
            quality={100}
            objectFit="contain"
            alt=""
          />
        </div>
        <h2 className="mb-6 text-center text-4xl font-medium text-white">{title}</h2>
        <form
          className="w-full max-w-xs flex flex-col justify-center items-center"
          onSubmit={onSubmit}
        >
          <label htmlFor="email" className="sr-only">
            Email address
          </label>
          <input
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            value={email}
            name="email"
            type="email"
            autoComplete="email"
            required
            className="relative mb-4 block w-full px-4 py-3 border rounded-md border-gray-100 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-yellow-500 focus:z-10"
            placeholder="Your best email"
          />
          <Button
            type="submit"
            btnColor="bg-yellow-600"
            btnHoverColor="bg-yellow-700"
            className="w-full"
          >
            {buttonText}
          </Button>
        </form>
        {errorMsg && <p className="text-red-400 mt-4">{errorMsg}</p>}
      </div>
    </>
  )
}
