import React from 'react'
import Auth from 'components/Auth'
import Seo from 'components/Seo'
import Layout from 'components/Layout'

export default function Signin() {
  return (
    <>
      <Seo />
      <Layout>
        <Auth type="signin" buttonText="Sign in" title="Sign in to your account" />
      </Layout>
    </>
  )
}
